/* Fonts are loaded on-demand by the browser, so we define all of the available
weights in case they are needed. */

/* HEADING FONT */
@font-face {
  font-family: "NCKobyla";
  src: url("./fonts/nc-kobyla/NCKobyla-Ultra-Light.woff2") format("woff2");
  font-weight: 200;
  font-display: block;
}
@font-face {
  font-family: "NCKobyla";
  src: url("./fonts/nc-kobyla/NCKobyla-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: block;
}
@font-face {
  font-family: "NCKobyla";
  src: url("./fonts/nc-kobyla/NCKobyla-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: block;
}
@font-face {
  font-family: "NCKobyla";
  src: url("./fonts/nc-kobyla/NCKobyla-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: block;
}
@font-face {
  font-family: "NCKobyla";
  src: url("./fonts/nc-kobyla/NCKobyla-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-display: block;
}
@font-face {
  font-family: "NCKobyla";
  src: url("./fonts/nc-kobyla/NCKobyla-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: block;
}

/* BODY FONT */
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-RegularItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-BoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "KHTeka";
  src: url("./fonts/kh-teka/KHTeka-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: block;
}
